@import "./vars";

.pac-container {
  margin: 33px 15px 0 25px;
  width: 290px !important;
}
.gm-style {
  .search-container {
    margin-top: 35px;
    margin-left: 15px;
    .selector {
      width: 100%;
      padding-right: 10px;
      .ui-selectbutton {
        display: flex;
        justify-content: stretch;
        .ui-button {
          border-left-color: black !important;
          border-left-width: 1px;
          border-left-style: solid;
          width: unset;
          flex: auto;
          .ui-button-text {
            white-space: nowrap;
          }
        }
        .ui-button.ui-state-active + .ui-button, .ui-button.ui-state-active {
          border-left-width: 0;
        }
        .ui-button.ui-state-active {
          background-color: $blue8;
          border-color: $blue8;
        }
      }
    }
  }
  .place-search {
    $iconSize: 30px;
    button.reset {
      margin-left: -20px;
      height: $iconSize !important;
      width: $iconSize !important;
      border-radius: $iconSize * 0.5;
    }
    button.clear {
      margin-left: 20px;
    }
    display: flex;
    align-items: center;

    .icon-i-search {
      position: absolute;
      z-index: 1;
      left: -8px;
      background-color: $green1;
      height: $iconSize;
      width: $iconSize;
      border-radius: $iconSize * 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
    }

    input {
      &::placeholder {
        color: white;
        opacity: 1;
      }
      background-color: $invBackground;
      color: white;
      font-size: large;
      border-radius: 21px;
      padding: 5px 5px 5px 50px;
      margin: 0 -15px;
      min-width: 340px;
      height: 42px;
      outline: none;
      border: 2px solid $blue8;

    }
  }
  .place-search-popup div {
    margin-top: 5px;
  }
}

.light .gm-style {
  .place-search {

    input {
      &::placeholder {
        color: $blue2;
        opacity: .75;
      }
      background-color: $white;
      border: 2px solid $blue12;
      color: $blue2
    }
  }
}

// Search box buttons ....
.gmap-places-button-container {
  margin-top: 3px;
  width: 310px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 21px;
  background-color: $blue6;
  border: 2px solid $blue8;
}

.light .gmap-places-button-container {
  background-color: $white;
  border: 2px solid $blue12;
}


.gmap-places-option-btn {
  height: 30px;
  width: 33.33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: .85rem;
  border-radius: 21px;
  &:hover {
      font-size: .9rem;
      cursor: pointer;
  }
  &.selected {
      background-color: $blue7;
      border: 1.5px solid $blue8;
      &.gmap-places-map-btn {
          border-left: none;
      }

      &.gmap-places-cad-btn {
          border-right: none;
      }
  }
}

.light .gmap-places-option-btn {
  color: $blue2;
  &.selected {
    color: $white;
    background-color: $blue12;
    border: 1.5px solid $blue12;
  }
}
// ......................................



@keyframes drop {
  from {top: -400px;}
  to {top: 0;}
}

.incident-marker {
  user-select: none;
  &.drop {
    // https://cubic-bezier.com/#.4,1.08,.29,.99
    animation: .5s cubic-bezier(.4,1.08,.29,.99) drop;
  }

  .title {
    display: none;
    white-space: nowrap;
    text-align: center;
    position: absolute;
    left: 50%; // of parent
    color: black;
    padding: 5px;
    background-color: white;
    box-shadow: 3px 3px 3px black;
    border: 1px solid black;
    border-radius: 3px;
    z-index: 1;
    transform: translateX(-50%) translateY(-65px); // of me
  }
  .rounds {
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    position: absolute;
    color: black;
    left: 50%;
    transform: translate(-50%, -30px);
  }
  &.has-focus .rounds {
    font-size: 20px;
    transform: translate(-50%, -36px);
  }
  &:hover {
    cursor: pointer;
    z-index: 99999!important;
  }
  &:hover .title {
    display: block;
  }

  img.icon {
    width: 30px;
    height: 45px;
    display: block;
    position: absolute;
    transform: translate(-14px, -33px);
  }
  &.has-focus {
    img.icon {
      transform: translate(-14px, -36px) scale(1.3);
    }
    .reclassified {
      height: 17px * 1.3;
      width: 17px * 1.3;
      left: 5px;
      top: -48px;
    }
  }
  &.suppressed  {
    .rounds {
      font-size: 15px;
      transform: translate(-50%, -50%);
    }
    img.icon {
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
    }
    &.has-focus img.icon{
      width: 20px * 1.2;
      height: 20px * 1.2;
      transform: translate(-50%, -50%) rotate(45deg);
      transform-origin: 12px 12px;
    }

  }
  // proximal label with time
  .label {
    position: absolute;
    background-color: $sst-red;
    color: $primary-text-color;
    padding: 2px 4px;
    border-radius: 4px;
    transform: translateX(-50%) translateY(13px);

    &.after {
      background-color: $primary-highlight-color;
    }
  }

  .reclassified {
    background-image: asset('reclassified.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 3px;
    top: -38px;
    height: 17px;
    width: 17px;
  }

}

.shot-marker {
  position: absolute;
  .title {
    display: none;
    white-space: nowrap;
    text-align: center;
    position: absolute;
    left: 50%; // of parent
    color: black;
    padding: 5px;
    background-color: white;
    box-shadow: 3px 3px 3px black;
    border: 1px solid black;
    border-radius: 3px;
    z-index: 1;
    transform: translateX(-50%) translateY(-40px); // of me
  }
  &:hover {
    z-index: 999999!important;
    cursor: pointer;
    .title {
      display: block;
    }
  }
  $size: 22px;
  .icon {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(-50%, -50%);
    height: $size;
    width: $size;
    border-radius: 50%;
    background-color: $gunshot;
    border: 1px solid black;
    color: black;
    font-size: $size * 0.5;
    font-weight: bold;
  }
  &.type-19 .icon {
    background-color: $probable-gunshot;
  }
  &.type-25 .icon {
    background-color: white;
  }
  &.type-26 .icon {
    background-color: #FF981F;
  }
  &.active {
    animation: pulse .5s;
    animation-iteration-count: 1;
  }
  &.faded {
    animation: fade .9s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}

	30% {
		box-shadow: 0 0 20px 12px rgba(255, 255, 255, .85);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}



%close-icon {
  // need to replace close icon
  // this copies icon font features
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: block;
  position: absolute;
  top: 6px;
  right: 6px;
  color: $white;
  content: "\ea0f"; // close icon
}






/* infoWindow styling */
.gm-style {
  .gm-style-iw , .gm-style-iw-t::after {
    background: $deepblue;
  }
  .gm-style-iw-d {
    padding: 10px;
  }
  .gm-style-iw {
    color: white;
    padding-left: 0;
    &:before {
      @extend %close-icon;
      cursor: pointer;
      &:hover {
        opacity: .7;
      }
    }
    button {
      span {
        visibility: hidden;
      }
      opacity: 0;
    }
  }
}

.light .gm-style {
  .gm-style-iw , .gm-style-iw-t::after {
    background: $blue5;
  }
  .gm-style-iw {
    color: white;
  }
}

.area-label {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  background-color: white;
  color: black;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.5);
  &.coverage-area {
    background-color: $sst-red;
    color: white;
  }
  &.district {
    background-color: #7AC1FF;
    color: white;
  }
  &.beat {
    background-color: #66D7A3;
    color: white;
  }
  &.hint {
    background-color: #FF981F;
    color: white;
  }
}

.zoom-sensors .sensor-label {
  font-size: 0;
  color: transparent !important;
  height: 12px;
  width: 12px;
  min-width: 12px;
  border: 1px solid $grey00;
  border-radius: 50%;
}

.sensor-label {
  user-select: none;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  // margin-top: -29px;
  white-space: nowrap;
  background-color: black;
  color: $grey00;
  border-radius: 5px;
  border: 1px solid $grey00;
  padding: 3px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
  // border: 2px solid $grey00;
  // min-width: 30px;
  text-align: center;
  vertical-align: middle;

  &.distant {
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
    }

  }

  &.review {
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    border: none;
    background-size: contain;

    &.participating {
      background-image: asset('sensor-icon-participating.png');
    }
    &.non-participating {
      background-image: asset('sensor-icon-nonparticipating.png');
    }
    &.broken {
      background-image: asset('sensor-icon-broken.png');
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.participating {
    background-color: $green1;
    color: $grey00;
    transform: translateX(-17px);
  }

  &.non-participating {
   background-color: black;
   color: $grey00;
  }

  &.broken {
    background-color: $sst-red;
    color: $grey00;
  }

  &:hover {
    cursor: pointer;
  }
}

.google-logo {
  left: unset !important;
  right: 0 !important;
  bottom: 15px !important;
}


.gm-tilt {
  display: none;
}



