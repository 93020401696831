
// Define vars we'll be using
$check-height: 30px;
$check-width: $check-height*0.5;
$check-thickness: 4px;
$check-color: white;

$animation-length: 800ms;

.checkmark {
  opacity: 1 !important;
  .ui-button-text {
    // hide text
    // in favor of checkmark
    color: transparent;
  }

  &:after {
    animation-duration: $animation-length;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform-origin: top left;
    transform: scaleX(-1) rotate(135deg);
    height: $check-height;
    width: $check-width;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: calc(50% - #{$check-width + 4px});
    top: 50%;
    position: absolute;
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
  }
  20% {
    height: 0;
    width: $check-width;
  }
  40% {
    height: $check-height;
    width: $check-width;
  }
  100% {
    height: $check-height;
    width: $check-width;
  }
}