// these are intended to match the re-useable definitions from figma
%type-base {
  font-family: Roboto, sans-serif;
}

%type-header {
  @extend %type-base;

  font-weight: bold;
  text-transform: uppercase;
}

@mixin type-main-header {
  @extend %type-header;
  font-size: 15px;
}

@mixin type-small-header {
  @extend %type-header;
  font-size: 11px;
}

@mixin type-medium-header {
  @extend %type-header;
  font-size: 13px;
}

@mixin type-big-header {
  @extend %type-header;
  font-size: 18px;
}

@mixin type-timer {
  @extend %type-base;
  font-weight: bold;
  font-size: 30px;
}
