@import "../../../scss/vars.scss";


.measuretool-label {
  cursor: move;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  background-color: white;
  color: black;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.5);


  .delete {
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    .close {
      color: black;
      font-size: 10px;
      height: 20px;
      width: 20px;
      margin-left: 5px;
      margin-right: -3px; // offset the default negative margin for .close
    }
  }

  &:hover .delete {
    display: flex;
  }

}
