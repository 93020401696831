@import "./vars";
@import "./typography";
@import "./progress-check-success";

:root {
  --panel-background-color: #{$lessdarkblue};
}

$clip-size: 30px;
.sst-icon.clipboard {
  cursor: pointer;
  height: $clip-size;
  width: $clip-size;
  background-image: asset('clipboard.svg');
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  background-position: center;
  &.cb-small {
    height: 25px;
    width: 25px;
  }

}

.sst-icon.mir {
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-image: asset('mir.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  &.cb-small {
    height: 25px;
    width: 25px;
  }
}




i.close {
  height: 30px;
  width: 30px;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -5px;
  font-size: 13px;
  &.small {
    height: 18px;
    width: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: asset('close-sm.svg');
    opacity: .3;
    &:hover {
      opacity: 1;
    }
  }
  &:hover {
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.3);
  }

}

i.exclaim-circle {
  display: block;
  width: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: asset('exclaim-circle.svg');
  margin-right: 10px;
}

.invisible {
  visibility: hidden;
}

.pulse {
  animation: pulse 1s ease alternate infinite
}

@keyframes pulse {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: $warn-color;
  }
}

.bold {
  font-weight: bold;
}

.data-label {
  @include type-small-header;
  color: $grey1;
  text-transform: uppercase;
}

.button {
  @include type-main-header;

  display: flex;
  height: 25px;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-radius: 8px;
  border-width: 0;
  cursor: pointer;
  min-width: 90px;
  background-color: $secondary-highlight-color;
  color: $primary-text-color;

  &:hover {
    opacity: .7;
  }
}

.cfp-hotkeys {
  vertical-align: top!important;
}

.cfp-hotkeys-container tbody {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 50px;

  tr {
    width: 400px;
  }
  .cfp-hotkeys-key  {
    min-width: 100px;
  }
}

.nav-activities {
  background-image: asset('nav/activities.svg');
}
.nav-completed {
  background-image: asset('nav/completed.svg');
}

.scroller {
  flex: 1;
  height: 100%;
  overflow: auto;
  border: solid 1px $input-border;
  background: rgba(255,255,255, 0.6);
  border-radius: 3px 0 0 3px;
}

/* scrollbar handling
*/

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}
// ff support
.force-scrollbars {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0,0, .5) transparent;
}

.force-scrollbars::-webkit-scrollbar {
  display: initial;
  -webkit-appearance: none;
}

.force-scrollbars::-webkit-scrollbar:vertical {
  width: 11px;
}

.force-scrollbars::-webkit-scrollbar:horizontal {
  height: 11px;
}

.force-scrollbars::-webkit-scrollbar-thumb {
  border-radius: 8px;
  // border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

*::-webkit-scrollbar-corner {
  background: transparent;
}

.ui-table-scrollable-body {
  @extend .force-scrollbars;
}
/*
.force-scrollbars::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}
*/

// material design overrides

.mat-app-background {
  background-color: $light-panel-background;
}

.mat-form-field.mat-focused {
  .mat-form-field-label {
    color: $primary-text-color;
  }
  .mat-form-field-ripple {
    background-color: $blue1;
  }
}
.mat-expansion-panel {
  background-color: $blue2;
  width: 100%;
}

.mat-expansion-panel:not(.mat-elevation-z) {
  box-shadow: none;
}

.mat-expansion-panel-header {
  background-color: $blue2;
  padding: 0 10px !important;
  font-size: .85rem;
  height: 40px !important;
  border-top: 1px solid rgba(82, 172, 233, .5)
}

.mat-expansion-panel-body {
  border-bottom: 1px solid rgba(82, 172, 233, .5)
}

.mat-panel-title {
  padding-left: 5px;

}
.mat-input-element {
  caret-color: $blue1;
}
.mat-autocomplete-panel {
  background-color: $grey3;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background-color: $grey2;
}
.mat-raised-button.mat-primary {
  background-color: $blue1;
}
.mat-snack-bar-container {
  background-color: $grey4;
  box-shadow: 0px 0px 42px rgba(0, 0, 0, 0.83);
  &.error {
    background-color: $alert-red;
  }
  color: $primary-text-color;
}

.mat-progress-bar {
  height: 5px !important;
  &-background {
    fill: white;
  }
  &-buffer {
    fill: #03A3C2 !important;
    background-color: white;
  }
  &-fill:after {
    background-color: #03A3C2;
  }
}

.mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label, .mat-accent .mat-slider-track-fill {
  background-color: $green1;
}

.light .mat-slider-track-background {
  background-color: $blue18;
}


@keyframes slide-in-right {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
.slide-out-right {
  animation: slide-out-right 2s forwards;
}

.slide-in-right {
  animation: slide-in-right 2s forwards;
}
// loading.io spinner
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// https://www.sitepoint.com/replacing-radio-buttons-without-replacing-radio-buttons/
$radio-border: white;
$radio-button: $blue1;
/* HTML5 Boilerplate accessible hidden styles */
.radio[type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px; margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* One radio button per line
label {
  display: block;
  cursor: pointer;
  line-height: 2.5;
  font-size: 1.5em;
}*/

.radio[type="radio"] + span {
  display: block;
  cursor: pointer;
}

/* the basic, unchecked style */
.radio[type="radio"] + span:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.25em;
  border-radius: 1em;
  // border: 0.125em solid orange;
  box-shadow: 0 0 0 0.15em $radio-border;
  margin-right: 0.75em;
  transition: 0.5s ease all;
}

/* the checked style using the :checked pseudo class */
.radio[type="radio"]:checked + span:before {
  background: $radio-button;
  box-shadow: 0 0 0 0.25em $radio-border;
}


