@import 'vars';
@import 'mixins';

// use body / component name before class names to increase specificity over default themes.

// pinputtext
// --------------------------------------------------------------
body input.ui-inputtext {
  // height: 45px; // can't change height; it affects use in inputgroups (with button addons)
  border: solid 1px $input-border;

  &:enabled:hover:not(.ui-state-error), &:enabled:focus:not(.ui-state-error) {
    border-color: $input-border;
    box-shadow: unset;
  }
  &.panel-input {
    width: 125px;
    background-color: $blue7;
    border: 1px solid $blue7;
    font-size: .65rem;
    color: white;
    &:focus {
      border: 1px solid  rgba(82, 172, 233, .5) !important;
    }
    &:hover {
      border: 1px solid rgba(82, 172, 233, .5) !important;
    }
    &::placeholder {
      color: white;
      opacity: 0.5;
    }
  }
}
body {
  app-date-filter, app-area-filter, app-settings {
    input.ui-inputtext {
      height: 45px;
    }
  }
}

.light {
  body input.ui-inputtext {
    // height: 45px; // can't change height; it affects use in inputgroups (with button addons)
    border: solid 1px $blue12;
    background-color: $blue17;
    color: $blue2;

    &:enabled:hover:not(.ui-state-error), &:enabled:focus:not(.ui-state-error) {
      border-color: $blue12;
      box-shadow: unset;
    }
    &.panel-input {
      width: 125px;
      background-color: $white;
      border: 1px solid $white;
      font-size: .65rem;
      color: $blue2;
      &:focus {
        border: 1px solid  rgba(82, 172, 233, .5) !important;
      }
      &:hover {
        border: 1px solid rgba(82, 172, 233, .5) !important;
      }
      &::placeholder {
        color: $blue2;
        opacity: 0.5;
      }
    }
  }



  body {
    app-date-filter, app-area-filter, app-settings {
      input.ui-inputtext {
        height: 45px;
      }
    }
  }
}

// ui-inputgroup
// --------------------------------------------------------------
body div.ui-inputgroup {

  .ui-button:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
  .ui-inputgroup-addon {
    display: flex;
    align-items: center;
    border-color: $input-border;
    background: white;
    color: #333;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }

  // fixes bug in base styling when a calendar is used in an input group as the
  // second item, the inner text box's right border gets removed while selected.
  p-calendar:nth-child(2) {
    background: orange;
    .ui-inputtext {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-right: solid 1px $input-border;
    }
  }
}

// pbutton
// --------------------------------------------------------------
body button.ui-button.ui-state-default {
  height: 45px;
  border: none;
  background-color: $green1;
  outline: 0;
  flex-shrink: 0;

  &:enabled:hover,
  &:enabled:active,
  &:enabled:focus {
    background-color: $green1;
    outline: 0;
    box-shadow: none;
  }

  &:enabled:active {
    background-color: saturate(darken($green1, 10%), 10%);
  }

  &.ui-button-warning,
  &.ui-button-warning:enabled:hover,
  &.ui-button-warning:enabled:active,
  &.ui-button-warning:enabled:focus {
    background-color: $warn-color;
    border: none;
    color: white;
    box-shadow: none;
  }

  &.ui-button-warning:enabled:active {
    background-color: darken($warn-color, 10%);
  }
  &.clear-btn {
    color: $grey4;
    background-color: $white;
    border: none;
    &:enabled:hover,
    &:enabled:active,
    &:enabled:focus {
      background-color: $grey0;
      outline: 0;
      box-shadow: none;
    }
  }
  &.outline-btn {
    background-color: transparent;
    border: 1px solid $green1;
    &:enabled:focus {
      background-color: transparent;
      border: 1px solid $green1;
    }
    &:enabled:active {
      background-color: saturate(darken($green1, 10%), 10%);
    }
  }

  &.panel-btn {
    height: 30px;
    font-size: .65rem;
  }
}

.light {
  body button.ui-button.ui-state-default {
    height: 45px;
    border: none;
    background-color: $green1;
    outline: 0;
    flex-shrink: 0;

    &:enabled:hover,
    &:enabled:active,
    &:enabled:focus {
      background-color: $green1;
      outline: 0;
      box-shadow: none;
    }

    &:enabled:active {
      background-color: saturate(darken($green1, 10%), 10%);
    }

    &.ui-button-warning,
    &.ui-button-warning:enabled:hover,
    &.ui-button-warning:enabled:active,
    &.ui-button-warning:enabled:focus {
      background-color: $warn-color;
      border: none;
      color: white;
      box-shadow: none;
    }

    &.ui-button-warning:enabled:active {
      background-color: darken($warn-color, 10%);
    }
    &.clear-btn {
      color: $blue2;
      background-color: transparent;
      border: 1px solid $blue12;
      &:enabled:hover,
      &:enabled:active,
      &:enabled:focus {
        background-color: $blue17;
        border: 1px solid $blue12;
      }
    }
    &.outline-btn {
      color: $blue2;
      background-color: white;
      border: 1px solid $blue12;
      &:enabled:hover {
        background-color: $blue18;
        border: 1px solid $blue12;
      }
      &:enabled:active,
      &:enabled:focus {
        background-color: white;
        border: 1px solid $blue12;
      }
    }

    &.panel-btn {
      height: 30px;
      font-size: .65rem;
    }
    &.ui-button-secondary {
      color: $blue2;
      background-color: $blue18;
      &:hover {
        background-color: $blue12;
        color: $white;
      }
    }
  }
}

// p-dropdown
// --------------------------------------------------------------
body p-dropdown .ui-dropdown {
  width: 100%;
  height: 45px;
  border: solid 1px;
  display: flex;
  align-items: center;

  &:not(.ui-state-disabled):hover, &:not(.ui-state-disabled).ui-state-focus {
    box-shadow: unset;
  }
}

body p-dropdown.ng-invalid.ng-touched .ui-dropdown {
  border: 3px solid red;
}

.light {
  body {
    .ui-dropdown {
      border-color: $blue12;
      background-color: $blue17;
      .ui-dropdown-trigger {
        background-color: $blue17;
        color: $blue12;
      }
      &:not(.ui-state-disabled):hover {
        border-color: $blue2;
      }
    }
    .ui-dropdown-panel {
      border-color: $blue12;
      background-color: $blue17;
    }
    .ui-dropdown-label {
      color: $blue2;
      background-color: $blue17 !important;
    }
    .ui-dropdown-item {
      &.ui-state-highlight {
        background-color: $blue12 !important;
        color: $white !important;
        &:hover {
          color: $blue2 !important;
        }
      }
      &:hover {
        background-color: $blue18 !important;
        color: $blue2 !important;
      }
    }
  }
  &:not(.ui-state-disabled):hover, &:not(.ui-state-disabled).ui-state-focus {
    border-color: $blue18;
  }
}
.dark {
  body p-dropdown .ui-dropdown {
    border-color: $input-border;
  }
  &:not(.ui-state-disabled):hover, &:not(.ui-state-disabled).ui-state-focus {
    border-color: $input-border;
  }
  .ui-dropdown-item {
    &.ui-state-highlight {
      background-color: $blue5 !important;
      color: $white !important;
    }
  }
}

body .ui-autocomplete-panel {
  .ui-autocomplete-list-item.ui-state-highlight:hover {
    background-color: $blue18;
    color: $blue2;
  }
}

.light {
  body .ui-autocomplete-panel {
    background-color: $blue17;
    .ui-autocomplete-list-item.ui-state-highlight:hover {
      background-color: $blue18;
      color: $blue2;
    }

  }
}

// p-calendar
// --------------------------------------------------------------
body p-calendar {
  .ui-calendar {
    width: 100%;

    .ui-inputtext {
      width: 100%;
    }
  }
}

// p-tabview
// --------------------------------------------------------------
body p-tabview .ui-tabview {

  &.ui-tabview-top {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    > ul {
      margin: 0 0 1px 0 !important;
      display: flex;
      justify-content: space-evenly;
      background-color: $med-panel-background !important;
      > li {
        flex: 1;
        background-color: $med-panel-background !important;
        border-radius: 0;
        margin: 0 !important;
        border: none !important;
        border-left: 1px solid $grey1 !important;
        border-right: 1px solid $grey1 !important;
        border-bottom: 1px solid $grey1 !important;
        &:first-child {
          border-left: none !important;
        }
        &:last-child {
          border-right: none !important;
        }
        &:focus {
          outline: none;
        }
        a {
          text-transform: uppercase;
          color: $secondary-text-color !important;
          font-size: 14px;
          user-select: none;
        }
        &.ui-state-active, &.ui-tabview-selected {
          // background-color: $grey4 !important;
          border-bottom: none !important;
          a {
            color: $primary-text-color !important;
          }
        }
        &:active {
          a {
            color: $primary-text-color !important;
          }
        }
        &:hover:not(.ui-state-active):not(:active) {
          opacity: 0.7 !important;
        }
      }
    }
  }

  &.ui-tabview-left {
    height: 100%;
    padding: 0;

    .ui-tabview-nav {
      height: 100%;
      display: flex;
      flex-direction: column;

      li {
        flex: 1;
        margin: 0;
        border: none;
        border-top: solid 1px darken($warn-color, 10%);
        border-radius: 0;
        background: $warn-color;
        outline: 0;
        display: flex;
        align-items: center;

        &:first-child { border-top: none; }

        a {
          color: $primary-text-color;
        }
      }

      li:not(.ui-state-active):not(.ui-state-disabled):hover,
      li.ui-state-active,
      li.ui-state-active:hover {
        background: inherit;
        border: none;
        border-top: solid 1px darken($warn-color, 10%);

        &:first-child { border-top: none; }

        a {
          color: $med-panel-background;
        }
      }

    } // ui-tabview-nav

  } // ui-tabview-left

  .ui-tabview-panels {
    flex: 1;
    background: transparent;
    border: none;
    color: $med-panel-background;
    padding: 0;
    overflow: hidden;

    .ui-tabview-panel {
      height: 100%;
    }
  }

}

body p-slider .ui-slider .ui-slider-range {
  background-color: unset;
}

// p-inputswitch
// --------------------------------------------------------------
body p-inputswitch {
  .ui-inputswitch {
    height: 10px;
    width: 35px;

    .ui-inputswitch-slider {
      background: $grey1;

      &:before {
        height: 18px;
        width: 18px;
        left: -1px;
        bottom: -4px;
      }
    }

    &.ui-inputswitch-checked .ui-inputswitch-slider:before {
      transform: translateX(19px);
    }

    &.ui-inputswitch-focus .ui-inputswitch-slider {
      background: $grey1;
    }

    &:not(.ui-state-disabled):hover .ui-inputswitch-slider {
      background-color: $grey1;
    }

    &.ui-inputswitch-checked .ui-inputswitch-slider {
      background-color: $grey1;
    }

    &.ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider {
      background-color: $grey1;
    }

    &.ui-inputswitch-checked.ui-inputswitch-focus .ui-inputswitch-slider {
      background: $grey1;
    }
  }
}

// p-table
// --------------------------------------------------------------
body p-table {

  .ui-table-scrollable-header, .ui-table-scrollable-footer {
    overflow:unset;
  }
  .ui-table-scrollable-view {
    display: flex;
    flex-direction: column;
    .ui-table-scrollable-body {
      // handle dynamic sized header
      flex-grow: 1;
    }
  } 
}
// todo: move more non-color/theme related styling out of here.
.dark {
  body p-table {
    .ui-table {
      .ui-table-thead > tr > th, .ui-table-tbody > tr > td {
        padding: 8px 8px;
      }

      .ui-table-thead {
        tr {
          th {
            background-color: #0E2534;
            color: $grey0;
            border-color: rgba(255,255,255,.25);
            font-size: 11px;
            text-align: left;

            &:first-child { border-left: none; }
            &:last-child { border-right: none; }
          }
        }
      }

      .ui-table-tbody {
        tr {
          color: white;
          font-size: 13px;

          &:nth-child(even) { background-color: $blue6; }
          &:nth-child(odd) { background-color: var(--panel-background-color); }

          &.ui-selectable-row:focus {
            outline-color: $blue1;
          }

          td {
            border: none;
            .no-overflow {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    &.detail-table {
      tr {
        // border-right: 1px solid rgba($blue8, .4);
        // border-bottom: 1px solid rgba($blue8, .4);
        &:nth-child(even) { background: $blue5 !important; }
        &:nth-child(odd) { background: rgba($blue5, .2) !important; }
        &.fire {
          background-color: $gunshot !important;
          color: $grey5;
        }
      }
      th {
        background-color: $blue5 !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        color: $grey0 !important;
        font-size: .95rem;
        text-transform: uppercase;
        border-bottom: 2px solid white;
      }
    }
    &.results-grid {
      tr {
        color: white !important;
        &:hover {
          color: white !important;
        }
      }
      tr:nth-child(even) {
        background-color: $blue10 !important;
        &:hover {
          background-color: $blue11 !important;
        }
      }
      // ugly selector to override theme
      .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:nth-child(odd) {
        background-color: $blue13;
        &:hover {
          background-color: $blue14 !important;
        }
      }

      .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row.selected-incident {
        background-color: $blue12 !important;
        &:hover {
          background-color: $blue16 !important;
        }
      }

      .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row.ui-state-highlight:not(.selected-incident) {
        background-color: $blue15 !important;
        &:hover {
          background-color: lighten($blue15, 5) !important;
        }
      }

      .ui-table {
        height: 100%;
      }

      .ui-column-resizer {
        width: 1.5em !important;
      }

      .ui-table-scrollable-wrapper {
        height: 100%;

        .ui-table-scrollable-view {
          height: 100%;
        }

      }
    }
  }
}

.light {
  body {
    .ui-table-reorder-indicator-down,
    .ui-table-reorder-indicator-up {
      color: $grey5;
    }
  }
  body p-table {
    .ui-table {
      .ui-table-thead > tr > th, .ui-table-tbody > tr > td {
        padding: 8px 8px;
      }

      .ui-table-thead {
        tr {
          th {
            background-color: white;
            color: $blue2;
            border-color: $blue19;
            font-size: 11px;
            text-align: left;

            &:first-child { border-left: none; }
            &:last-child { border-right: none; }
          }
        }
      }

      .ui-table-tbody {
        tr {
          color: white;
          font-size: 13px;

          &:nth-child(even) { background-color: $blue17; }
          &:nth-child(odd) { background-color: white; }

          &.ui-selectable-row:focus {
            outline-color: $blue1;
          }

          td {
            border: none;
            .no-overflow {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    &.detail-table {
      tr {
        color: $blue2 !important;
        &:nth-child(even) { background: $blue17 !important; }
        &:nth-child(odd) { background: white !important; }
        &.fire {
          background-color: $gunshot !important;
          color: $grey5;
        }
      }
      th {
        background-color: $blue5 !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        color: $grey0 !important;
        font-size: .95rem;
        text-transform: uppercase;
        border-bottom: 2px solid white;
      }
    }
    &.results-grid {
      tr {
        color: $blue2 !important;
      }
      tr:nth-child(even) {
        background-color: $white !important;
        &:hover {
          background-color: $blue18 !important;
        }
      }

      // ugly selector to override theme
      .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:nth-child(odd) {
        background-color: $blue17;
        &:hover {
          background-color: $blue18 !important;
        }
      }

      .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row.selected-incident {
        background-color: $blue12 !important;
        color: white !important;
        &:hover {
          background-color: $blue16 !important;
        }
      }

      .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row.ui-state-highlight:not(.selected-incident) {
        // background-color: white !important;

        &:hover {
          background-color: $blue18 !important;
        }
      }

      .ui-table {
        height: 100%;
      }

      .ui-column-resizer {
        width: 1.5em !important;
      }

      .ui-table-scrollable-wrapper {
        height: 100%;

        .ui-table-scrollable-view {
          height: 100%;
        }

      }
    }

  }
}


// p-listbox
// --------------------------------------------------------------
body p-listbox .ui-listbox {
  width: 100%;
  border: none;
  background: none;

  .ui-listbox-header {
    .ui-listbox-filter-container {
      width: 100%;
      padding: 0;
    }
  }

  .ui-listbox-list-wrapper {
    .ui-listbox-list {
      background: none;

      .ui-listbox-item {
        outline: 0;

        &.ui-state-highlight {
          color: inherit;
          background-color: inherit;
        }
      }
    }
  }
}

/**
p-listbox with search header,
class ".fixed-search-header"
  - Set the header fixed to top
  - adds padding to the list to show the first item correctly
*/
body p-listbox.fixed-search-header .ui-listbox {

  /* Set the header with search to the top */
  .ui-listbox-header {
    position: absolute;
    margin-right: 27px;
    border-radius: 4px 0 0 0;
  }

  /* Adds padding to the top of the list to make room for the search box*/
  .ui-listbox-list-wrapper .ui-listbox-list {
    margin-top: 46px;
  }
}

// ensure the search box is above the 'text' of the checkbox icons that are scrolled below it
// .search-wrapper excludes the .is-open on the app-search component.
body .search-wrapper .is-open p-listbox.fixed-search-header .ui-listbox .ui-listbox-header {
  z-index: 1;
}

body p-listbox.no-h-scroll {
  .ui-listbox .ui-listbox-list-wrapper .ui-listbox-list .ui-listbox-item {
    width: 100%;
    overflow: hidden;
  }
}

.p-button {
  border: unset;
  background: $green1;
  &.reject {
    background: $sst-red;
  }

}

// p-dialog
// --------------------------------------------------------------

.p-dialog {
  border: unset;
  .p-dialog-header {
    color: white;
    background: var(--panel-background-color);
  }
  .p-dialog-content {
    background: var(--panel-background-color);
    color: white;
  }
}



body .ui-dialog.ui-widget {
  // width: 500px;
  // height: 250px;
  background-color: var(--panel-background-color);
  box-shadow: 0px 0px 42px rgba(0, 0, 0, 0.83);
  display: flex;
  flex-direction: column;
  .ui-dialog-titlebar {
    text-transform: uppercase;
    &-close {
      color: white;
    }
  }
  .ui-dialog-content, .ui-dialog-titlebar, .ui-dialog-footer {
    background-color: var(--panel-background-color);
    border: none;
    color: $primary-text-color;
    padding-left: 2em;
    padding-right: 2em;
  }

  .ui-dialog-content {
    flex: 1;
    padding-top: 0;
    padding-bottom: 1.25em;
    overflow: hidden;
    text-overflow: ellipsis;
    &:before { // horizontal line
      content: ' ';
      display: block;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.3);
      margin-bottom: 1em;
    }
  }

  .ui-dialog-footer {
    padding-bottom: 2em;
  }

  // component specific styling
  &.save-filter {
    input {
      flex: 1;
      height: unset;
    }
  }
}

.light {
  body .ui-dialog.ui-widget {
    .ui-dialog-content, .ui-dialog-titlebar, .ui-dialog-footer {
      background-color: var(--panel-background-color);
      color: $primary-text-color;
    }

    .ui-dialog-content {
      &:before { // horizontal line
        content: ' ';
        display: block;
        height: 2px;
        background-color: rgba(white, 0.3);
        margin-bottom: 1em;
      }
    }
  }
}

body .ui-dialog.ui-widget .ui-dialog-content, body .ui-dialog.ui-widget .ui-dialog-titlebar, body .ui-dialog.ui-widget .ui-dialog-footer {
  background-color: var(--panel-background-color);
}

// pTooltip
// --------------------------------------------------------------

body {
  .ui-tooltip {
    max-width: 100%;
    .ui-tooltip-text {
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
    &.nowrap .ui-tooltip-text {
      white-space: nowrap;
    }
    &.capitalize .ui-tooltip-text {
      text-transform: capitalize;
    }
  }
}


// p-tree
// --------------------------------------------------------------
body p-tree {
  display: block;
  overflow: hidden;

  &.full-height {
    height:100%;
    .ui-tree .ui-tree-wrapper {
      height:100%;
    }
  }

  .ui-tree {
    border: none;
    background: none;
    height: 100%;
    width: auto;
    padding: 0;
    display: flex;
    flex-direction: column;

    .ui-treenode-children {
      margin-left: 20px;
    }

    .ui-tree-filter-container {
      width: 100%;
      padding: 0;
    }

    .ui-tree-container {
      @extend %with-scrollbars;

      flex: 1;
      padding: 0;
      width: 100%;
      border: solid 1px $input-border;
      background: rgba(255,255,255, 0.6);
      border-radius: 3px 0 0 3px;
      // margin-top: 10px;

      .ui-treenode {

        // can't seem to add this only on the area component
        // but .beat and .district nodes should probably look
        // like this wherever they are.

        &.district, &.beat {
          .ui-chkbox-icon {
            margin: -1px 0px 0 -1px;
          }
        }

        &.district .ui-chkbox-box {
          border: 2px solid #7AC1FF;
        }

        // for type filter
        &.indent {
          margin-left: 20px;
        }

        &.beat .ui-chkbox-box {
          border: 2px solid #66D7A3;
        }

        .ui-treenode-content {
          outline: 0;
          &:focus {
            box-shadow: unset;
          }

          .ui-treenode-label {
            &.ui-state-highlight {
              background-color: inherit;
              color: inherit;
            }
          }

          .ui-chkbox {
            .ui-chkbox-icon {
              margin: -1px -1px 0 0; // fixing mis-position check
            }
          }
        }
      }
    }
  }
}

body p-radioButton {
  .ui-radiobutton-label {
    font-size: .95rem;
  }
}

body .ui-chkbox .ui-chkbox-box {
  &.ui-state-active {
    background-color: $green1;
    &.ui-state-focus {
      background-color: $green1;
      border: white;
    }
  }


  &.coverage {
    border: 2px solid red;
  }

}

.light {
  body .ui-chkbox .ui-chkbox-box {
    &.ui-state-active {
      background-color: $blue12 !important;
      &.ui-state-focus {
        background-color: $blue12 !important;
        border: white;
      }
    }
  }
}

// progress bar

body p-progressbar {
  .ui-progressbar {
    height: 8px;
    background-color: black;
  }
}

body .ui-progressbar .ui-progressbar-value {
  background: white;
}

.light {
  body .ui-progressbar {
    background-color: $blue18;
    .ui-progressbar-value {
      background: $blue12;
    }

  }
}



body .ui-overlaypanel {
  height: 100px;
  width: 200px;
  background-color: $blue7;
  border: 1px  solid rgba(82, 172, 233, .5);
  &::before {
    border-color: rgba(200, 200, 200, 0);
    border-bottom-color: rgba(82, 172, 233, 1);
  }

  &::after {
    border-color: rgba(200, 200, 200, 0);
    border-bottom-color: rgba(82, 172, 233, 1);
  }
}

body .ui-autocomplete .ui-autocomplete-panel {
  width: 100%; // override min-width
}

body .ui-tieredmenu {
  border-color: $blue1;
  border-width: 1px;
  border-style: solid;
  width: 220px;
  .ui-menuitem {
    border-color: $blue1;
    border-width: 1px;
    border-style: solid;
    border-radius: 0;
  }
  .ui-menuitem > .ui-menuitem-link {
    background-color: $blue6;
    .ui-menuitem-text, .ui-menuitem-icon {
      color: white !important;
    }
    .ui-menuitem-icon {
      font-size: 1.5rem !important;
    }
  }
  .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
    background-color: $blue5;
  }
}

.help-menu {
  transform: translate(55px, -34px) !important;
}

.light {
  body .ui-tieredmenu {
    border-color: $blue12;
    border-width: 1px;
    border-style: solid;
    width: 220px;
    .ui-menuitem {
      border-color: $blue12;
      border-width: 1px;
      border-style: solid;
      border-radius: 0;
    }
    .ui-menuitem > .ui-menuitem-link {
      background-color: $white;
      .ui-menuitem-text, .ui-menuitem-icon {
        color: $blue12 !important;
      }
      .ui-menuitem-icon {
        font-size: 1.5rem !important;
      }
    }
    .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
      background-color: $blue19;
    }
  }
}
