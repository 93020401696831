@import "../../../scss/mixins";

$icon-size: 24px;
$icon-margin: 3px;
.toolbar-controls {
  display: flex;
  margin: $icon-margin;
  padding: $icon-margin;
  // box-shadow: 0 2px 6px rgba(0,0,0,.3);
  text-align: center;
  line-height: $icon-size;
  border-radius: $icon-size;
  background-color: rgb(0, 0, 0);
  align-items: center;
  &.vertical {
    flex-direction: column;
  }
}


.toolbar-control .icon {
  height: $icon-size;
  width: $icon-size;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  margin: $icon-margin;
}
.toolbar-controls.vertical .toolbar-control:first-child {
  margin-top: -8px;
}
.toolbar-controls.vertical .toolbar-control .icon{
  margin-top: 12px;
}

// touch devices keep the :hover state as if it's a :focus or :active state
// resulting in a 'stuck' opacity effect
.toolbar-control .icon.off {
  opacity: .3;
}

@media (hover: hover) {
  .toolbar-control .icon:hover {
    opacity: .7;
  }
  .toolbar-control .icon.zoom-coverage-area:hover {
    opacity: 1;
    background-image: asset('zoom-coverage-area-hover.svg');
  }
  .toolbar-control .icon.zoom-participating-sensors:hover {
    opacity: 1;
    background-image: asset('zoom-participating-sensors-hover.svg');
  }
  .toolbar-control .icon.zoom-incident:hover {
    opacity: 1;
    background-image: asset('zoom-incident-hover.svg');
  }
  .toolbar-control .icon.zoom-fullscreen:hover {
    opacity: 1;
    background-image: asset('zoom-fullscreen-hover.svg');
  }
  .toolbar-control .icon.map-type:hover {
    opacity: 1;
    background-image: asset('map-type-green.svg');
  }
  .toolbar-control .icon.zoom-in:hover {
    opacity: 1;
    background-image: asset('zoom-in-hover.svg');
  }
  .toolbar-control .icon.zoom-out:hover {
    opacity: 1;
    background-image: asset('zoom-out-hover.svg');
  }
}

.toolbar-control .icon.propagation {
  background-image: asset('propagation.svg');
}

.toolbar-control .icon.participating-sensors {
  background-image: asset('sensor-icon-participating.svg');
}
.toolbar-control .icon.non-participating-sensors {
  background-image: asset('sensor-icon-nonparticipating.svg');
}
.toolbar-control .icon.search-results {
  background-image: asset('search-result.svg');
  background-position: 5px 0;  // deal with wierd size and position of svg image
  $fudge-factor: 8px;
  height: $icon-size;
  width: $icon-size;
  margin-bottom: 0px;
  margin-right: 8px;
  // margin: $icon-margin;
}
.toolbar-control .icon.proximal-incidents {
  background-image: asset('proximal-incident-toggle.svg');
  background-position: 5px 0;  // deal with wierd size and position of svg image
  $fudge-factor: 8px;
  height: $icon-size + $fudge-factor;
  width: $icon-size + $fudge-factor;
  margin: $icon-margin -$fudge-factor;
}
.toolbar-control .icon.non-gunfire {
  background-image: asset('non-gunfire-toggle.svg');
}
.toolbar-control .icon.error-ellipse {
  background-image: asset('error-ellipse-toggle.svg');
}
.toolbar-control .icon.shot-markers {
  background-image: asset('shot-markers-toggle.svg');
}
.toolbar-control .icon.coverage-areas {
  background-image: asset('coverage-area-toggle.svg');
}
.toolbar-control .icon.districts {
  background-image: asset('districts-toggle.svg');
}
.toolbar-control .icon.beats {
  background-image: asset('beats-toggle.svg');
}

.toolbar-control .icon.hints {
  background-image: asset('hints-toggle.svg');
}

.toolbar-control .icon.labels {
  // background-image: asset('labels-toggle.svg');
  &::after {
    content: 'A';
    font-size: $icon-size;
    color: $grey00;
  }
}

.toolbar-control .icon.sensor-labels {
  // background-image: asset('labels-toggle.svg');
  &::after {
    content: '#';
    font-size: $icon-margin;
    color: $grey00;
  }
}


.toolbar-control .icon.zoom-coverage-area {
  background-image: asset('zoom-coverage-area.svg');
}
.toolbar-control .icon.zoom-participating-sensors {
  background-image: asset('zoom-participating-sensors.svg');
}
.toolbar-control .icon.zoom-incident {
  height: $icon-size + 3px;
  width: $icon-size + 3px;
  margin: 3px -3px;
  background-image: asset('zoom-incident.svg');
}
.toolbar-control .icon.zoom-fullscreen {
  background-image: asset('zoom-fullscreen.svg');
}
.toolbar-control .icon.map-type {
  background-image: asset('map-type.svg');
}
.toolbar-control .icon.zoom-in {
  background-size: $icon-size - 3px;
  background-image: asset('zoom-in.svg');
}
.toolbar-control .icon.zoom-out {
  background-size: $icon-size - 3px;
  margin-top: 0;
  background-image: asset('zoom-out.svg');
}
