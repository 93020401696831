
@import './functions';

// from figma component design
$grey00: #F0F3F5;
$grey0: #C7D3DD;
$grey01: #9BADB8;
$grey1: #6E8693;
$grey2: #4A6473;
$grey3: #344A57;
$grey: $grey3;
$grey4: #0E2534;
$grey5: #021019;
$neutral-grey: #ECECEC;


// $green1: #3DB992;
$green1: #3DB992;
$green2: #107A67;
$green3: #3E9379;
$green4: #09705D;

$blue1: #17A4C5;
$blue2: #194161;
$blue3: #1f4d71;
$blue4: #0C2B44;
$blue5: #2A5C84;
$blue6: #092F4C;
$deepblue: #022F4C;
$blue7: #245378;
$blue8: #52ace9;
$blue9: #2E587A;
$blue10: #17486D;
$blue11: #155176;
$blue12: #03A3C2;
$blue13: #225A81;
$blue14: #1F6187;
$blue15: #073150;
$blue16: #07b6da;

$darkblue: #04121C;
$darkblue2: #02101B;
$darkblue3: #092133;
$lessdarkblue: #23465D;




// light theme
$blue17: #EFF4F6;
$blue18: #D6E9EF;
$blue19: #D3E3E8;
$white: white;
// $blue17? audio bgd
// $blue1 wav




// for reference only. used in index.html meta theme-color header
$theme-color: #1976d2;

$light-panel-background: $grey;
$med-panel-background: #283D49;
$med-panel-divider: #53646D;
$dark-panel-background: $grey4;
$extra-dark-panel-background: #021019;
$med-dark-highlight: #142530;
$panel-worker: $neutral-grey;

$input-border: #BDCCD6;

$primary-highlight-color: $green1;
$secondary-highlight-color: $blue16;
$tertiary-highlight-color: #BF35FF;

$primary-text-color: white;
$dark-text-color: $primary-text-color;

$secondary-text-color: $secondary-highlight-color;
.secondary-color {
  color: $secondary-highlight-color;
}
.primary-color {
  color: $primary-highlight-color;
}

$light-text-color: $grey5;

$highlight-row-background: #1C323E;
$highlight-row-border: $secondary-highlight-color;

$dark-dark: black;
$sst-red: #EA2020;
// $heading-text-color: #37ABC4;
$heading-text-color: #04C1F0;

$warn-color: #FF981F;
$ok-color: $primary-highlight-color;
$light-grey-color: #8499AD;

$dark-grey-text-color: rgba(229, 229, 229, 0.558);
$grey-text-color: $dark-grey-text-color;
$dark-text-input-background: #091922;

// $alert-red: #EE2929;
$alert-red: $sst-red;
$gunshot: #F7F7A2;
$probable-gunshot: #d575c8;

$break-small: 730px;
$break-large: 1025px;
$midsizeBreakSize: 1162px;

$selected-card-background: #567485;

$invBackground: $blue6;
$reviewBackground: $light-panel-background;
$filterPanelWidth: 300px;


