/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/purple-green.css";
@import "./fonts/icomoon/style.css";

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import "./scss/functions";
@import "./scss/vars";
@import "./scss/common";
@import "./scss/primeng";
@import "./scss/map";
@import "./app/shared/mapToolbar/mapToolbar";
@import "./app/shared/measuretool/measuretool.scss";
body {
  font-family: 'Roboto', sans-serif;
  color: $primary-text-color;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.hidden {
  display: none!important;
}
