@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?oreoqn');
  src:  url('fonts/icomoon.eot?oreoqn#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?oreoqn') format('truetype'),
    url('fonts/icomoon.woff?oreoqn') format('woff'),
    url('fonts/icomoon.svg?oreoqn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-settings:before {
  content: "\e91f";
}
.icon-close:before {
  content: "\e923";
}
.icon-clipboard:before {
  content: "\e920";
}
.icon-mir:before {
  content: "\e921";
}
.icon-ils:before {
  content: "\e922";
}
.icon-help:before {
  content: "\e91c";
}
.icon-logout:before {
  content: "\e91d";
}
.icon-reports:before {
  content: "\e91e";
}
.icon-no-sync:before {
  content: "\e91a";
}
.icon-sync:before {
  content: "\e91b";
}
.icon-area:before {
  content: "\e90f";
}
.icon-patrol:before {
  content: "\e915";
}
.icon-keyword:before {
  content: "\e916";
}
.icon-shapes:before {
  content: "\e917";
}
.icon-gps-location:before {
  content: "\e918";
}
.icon-calendar1:before {
  content: "\e919";
}
.icon-trash-can:before {
  content: "\e906";
}
.icon-gps-location-v0:before {
  content: "\e907";
}
.icon-calendar1-v0:before {
  content: "\e908";
}
.icon-page-right:before {
  content: "\e904";
}
.icon-page-left:before {
  content: "\e905";
}
.icon-rebel:before {
  content: "\e903";
}
.icon-triangle-left:before {
  content: "\e902";
}
.icon-i-search:before {
  content: "\e900";
}
.icon-i-3-vert:before {
  content: "\e901";
}
.icon-circle:before {
  content: "\e912";
}
.icon-clear:before {
  content: "\e913";
}
.icon-line:before {
  content: "\e914";
}
.icon-region:before {
  content: "\e90c";
}
.icon-tag:before {
  content: "\e90d";
}
.icon-status:before {
  content: "\e90e";
}
.icon-calendar:before {
  content: "\e910";
}
.icon-incident_type:before {
  content: "\e911";
}
.icon-shape:before {
  content: "\e90a";
}
.icon-circle1:before {
  content: "\e90b";
}
.icon-copy:before {
  content: "\e92c";
}
.icon-pencil2:before {
  content: "\e909";
}
.icon-floppy-disk:before {
  content: "\e962";
}
.icon-spinner11:before {
  content: "\e984";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-stack:before {
  content: "\e92e";
}
.icon-key:before {
  content: "\e98d";
}
.icon-power:before {
  content: "\e9b5";
}
