@import "./vars";

@mixin responds-to($media) {
    @if $media == phone {
        @media (max-width: $break-small) and (orientation: portrait) { @content; }
    }
    @else if $media == tablet {
        @media (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
    }
    @else if $media == mobile {
        @media (max-width: $break-large - 1) { @content; }
    }
    @else if $media == desktop {
        @media (min-width: $break-large) { @content; }
    }
}

%left-panel-base {
  position: absolute;
  min-width: 345px;
  top: 0;
  left: -3px;
  padding-left: 6px;
  transform: translateX(-100%);
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: var(--panel-background-color);
  max-height: calc(100vh - 190px);
  @extend %unstyled-fieldset;
}

%unstyled-fieldset {
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-end: 0;
    padding-inline-start: 0;
    min-inline-size: initial;
  }
}

%icon-hit-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

%nav-icon {
  @extend %icon-hit-box;
  height: 40px;
  width: 40px;
  color: $green1;
}

%hover-outline {
  &:hover {
      box-shadow: 0 0 0 1px;
  }
}

%with-scrollbars {
  &::-webkit-scrollbar {
    display: block;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .5);
  }
  
  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  
  &::-webkit-scrollbar:vertical {
    width: 11px;
  }
}
